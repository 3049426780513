@use "sass:map";
@import "../../styles/variables";
@import "../../styles/media_queries";

.ReachTheMallHome {
    display: flex;
    flex-direction: row;
    gap: 4.5rem;
    justify-content: center;

    @include breakpoint-under("lg") {
        flex-wrap: wrap;
        gap: 0;
    }

    .theMallInfo {
        @include breakpoint-under("md") {
            width: 100%;
        }
    }

    .howToReach {
        align-self: flex-end;
        height: max-content;
        background-color: map.get($theme-colors, "light");
        margin-top: 4rem;
        @include unna-italic;

        @include breakpoint-under("lg") {
            margin-top: 0;
        }
    }

    .howToReachTitleContainer {
        padding: 4rem 3rem 0 3rem;

        @include breakpoint-under("md") {
            padding: 4rem 1rem 0 1rem;
        }

        .title{
            height: 37px;

            font-size: 2rem;
            @include breakpoint-under("sm") {
                height: auto;
                font-size: 1.65rem;
            }
            @include breakpoint-under("s") {
                font-size: 1.45rem;
            }
        }
    }

    .howToReachLinkContainer {
        margin-top: 2rem;
        margin-bottom: 4rem;
        padding: 0rem 3rem;

        @include breakpoint-under("md") {
            padding: 0rem 1rem;
            margin-bottom: 3rem;
        }
    }

    .howToReachLink {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 1rem 0;
        align-items: center;
        transition: all .5s ease;

        &:nth-child(1) {
            border-top: 1px solid map.get($theme-colors, "gold");
        }

        &:nth-child(2) {
            border-top: 1px solid map.get($theme-colors, "gold");
            border-bottom: 1px solid map.get($theme-colors, "gold");
        }

        &:nth-child(3) {
            border-bottom: 1px solid map.get($theme-colors, "gold");
        }

        &:hover{
           color:  map.get($theme-colors, "medium-gold")
        }

        .linkTitle {
            font-size: 2rem;
            @include breakpoint-under("sm") {
                font-size: 1.65rem;
            }
            @include breakpoint-under("s") {
                font-size: 1.45rem;
            }
        }
    }

    .howToReachImg {
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        img {
            width: 100%;
        }
    }
}
