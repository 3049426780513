@use "sass:map";
@import "../../styles/variables";
@import "../../styles/media_queries";

.ExperienceCarousel {
  background-color: map.get($theme-colors, "extra-dark");
  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $bg-outlet-website;
    color: map.get($theme-colors, "medium-gold");
    font-size: 1rem;
    padding-bottom: 2.875rem;
    @include mulish-light;

    @include breakpoint-under("md") {
      padding-bottom: 2.375rem;
    }
  }

  .carouselSlideContent {
    position: relative;
    box-sizing: border-box;

    .slideExperienceInfo {
      display: flex;
      align-items: center;
      justify-content: center;
      color: map.get($theme-colors, "light");
      margin-top: 2.375rem;
      font-size: 1.875rem;
      gap: 1.25rem;
      padding: 1rem 1.5rem;
      @include unna-italic;
    }

    .slideName {
      position: absolute;
      color: map.get($theme-colors, "light");
      font-size: 3.125rem;
      @include unna-italic;
      font-weight: lighter;
      z-index: 2;
      bottom: 30px;
      left: 100px;
      opacity: 0;
      transition: all 0.5s ease;

      @include breakpoint-under("xl"){
          left: 65px;
      }

      .subtitle {
        font-size: 1rem;
        @include mulish-light;
        font-style: normal;
      }
    }

    .disappear {
      opacity: 1;
    }
  }

  .experienceDesc {
    font-size: 1rem;
    font-style: normal;
    @include mulish-semi-bold;
  }

  .experienceInfo {
    background-color: map.get($theme-colors, "special2");
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .experienceButton {
    padding-bottom: 3.125rem;
    width: 244px;
    @include breakpoint-under("md") {
      margin-top: 4.3125rem;
    }
  }

  .experienceNav {
    max-width: 1100px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    color: map.get($theme-colors, "gray");
    margin-bottom: 4.375rem;
    margin-top: 1rem;
    font-size: 1.375rem;
    @include unna-italic;
    font-weight: lighter;
    @include breakpoint-under("md") {
      display: none;
    }

    .nav {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 1rem;
      border-bottom: 1px solid map.get($theme-colors, "gold-regular");
      @include breakpoint-under("xl") {
        margin: 0 1rem;
      }

      .item {
        cursor: pointer;
        transition: all 0.5s ease;
        display: flex;
        align-items: center;
        gap: 0.8rem;
        width: 195px;

        .arrowIcon {
          opacity: 0;
          transition: all 0.5s ease;
        }

        &.selected {
          color: map.get($theme-colors, "light");
          .arrowIcon {
            opacity: 1;
          }
        }

        &:hover {
          color: map.get($theme-colors, "light");
        }
      }
    }

    .experienceIndex {
      height: 1px;
      width: 80px;
      background: map.get($theme-colors, "light");
      position: absolute;
      bottom: -1px;
      z-index: 1;
      transition: all 0.5s ease-in-out;
    }
  }
}
