@use "sass:map";
@import "../../styles/variables";
@import "../../styles/media_queries";

.HomeContent {
    .headCarousel {
        height: 656px;
        background-color: map.get($theme-colors, "extra-dark");
        @include breakpoint-under("md") {
            height: 476px;
        }
    }

    .cardCarousel {
        background: linear-gradient(to bottom, map.get($theme-colors, "extra-dark") 60%, transparent 40%);
        overflow-x: hidden;
        @include breakpoint-under("md") {
            background: linear-gradient(to bottom, map.get($theme-colors, "extra-dark") 50%, transparent 50%);
        }
    }

    .mailOrder {
        margin-top: 3.125rem;
        height: 158.5px;
    }

    .aboutTheMall {
        margin-top: 4.5rem;
    }

    .fakeMap {
        width: 100%;
        height: 700px;
        background-color: #000;
        margin-top: 8rem;

        @include breakpoint-under("lg") {
            margin-top: 0;
        }

        @include breakpoint-under("lg") {
            height: 620px;
        }
    }

    .reachTheMall {
        position: relative;
        top: -150px;

        @include breakpoint-under("lg"){
            position: unset;
            margin-top: 3rem;
        }

        @include breakpoint-under("md") {
            margin-top: 0;
        }
    }

    .expCarousel {
        @include breakpoint-under("lg") {
            margin-top: 2.6875rem;
        }
    }

    .ourRestaurants {
        margin-top: 7.125rem;
        margin-bottom: 7.125rem;
        @include breakpoint-under("lg") {
            margin-top: 4.5rem;
            margin-bottom: 4.5rem;
        }
    }
}