@use "sass:map";
@import "../../styles/variables";

.DonutChart {
    @include mulish-regular;

    @keyframes reel {
        0% {
            stroke-dasharray: 0 260;
        }
        100% {
            stroke-dasharray: 260 260;
        }
    }

    .donutchartTrack {
        fill: transparent;
        stroke: transparent;
        stroke-width: 26;
    }
    .donutchartIndicator {
        fill: transparent;
        stroke: map.get($theme-colors, "light");
        stroke-width: 26;
        stroke-dasharray: 0 10000;
        transition: stroke-dasharray 0.3s ease;
        animation: reel 10s linear;

        &.animated {
            animation-delay: .8s;
        }
    }

    .donutchart {
        margin: 0 auto;
        border-radius: 50%;
        display: block;
    }

    .donutchartText {
        fill: map.get($theme-colors, "gold-regular");
    }
    .donutchartTextVal {
        font-size: 22px;
    }
    .donutchartTextPercent {
        font-size: 14px;
    }
    .donutchartTextLabel {
        font-size: 9px;

        &.onlyLabel {
            font-size: 1rem;
        }
    }
}
