@use "sass:map";
@import "../../styles/variables";
@import "../../styles/media_queries";

.AboutTheMall {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @include mulish-light;

  @include breakpoint-under("xl") {
    flex-wrap: wrap;
    background-color: map.get($theme-colors, "extra-dark");
  }

  .FadeContainer{
    max-width: 642px;
    width: 100%;
  }

  .aboutInfo {
    background-color: map.get($theme-colors, "special1");
    padding: 4.5rem 7.5rem 6.375rem 7.5rem;
    position: relative;

    @include breakpoint-under("lg") {
      max-width: 100%;
      height: 100%;
    }

    @include breakpoint-under("lg") {
      padding: 4.5rem 3rem 2rem 3rem;
    }

    &::before {
      content: "";
      width: 4px;
      height: 145px;
      background: map.get($theme-colors, "gold");
      position: absolute;
      top: -75px;
      left: 77px;
      @include breakpoint-under("md") {
        left: 20px;
      }
    }

    .accordionButton {
        background: none;
        border: none;
        color: map.get($theme-colors, "gold");
        font-size: 2.25rem;
        @include mulish-light;

        div:nth-child(1) {
            height: 1.25px;
            width: 15px;
            background: map.get($theme-colors, "gold");
            transition: all .25s ease;

            &.hideLineFirst {
                transform: rotate(-180deg);
            }
        }

        div:nth-child(2) {
            height: 1.25px;
            width: 15px;
            position: relative;
            top: -1px;
            background: map.get($theme-colors, "gold");
            transform: rotate(-90deg);
            transition: all .25s ease;

            &.hideLine {
                transform: rotate(0deg);
            }
        }
    }

    .aboutInfoSubtitle {
      color: map.get($theme-colors, "light");
    }
    .aboutInfoTitle {
      color: map.get($theme-colors, "light");
      font-size: 2rem;
      margin: 2rem 0;
      height: fit-content;
      @include unna-italic;
    }
    .aboutInfoDesc {
      display: inline;
      color: map.get($theme-colors, "light");
      font-size: 1rem;
      line-height: 1.5;
      max-height: 600px;
      overflow: hidden;
      transition: all 0.5s ease;
    }
    .aboutInfoDesc + .aboutInfoDesc::before {
      content: ' ';
    }
    .aboutInfoDescClosed {
      display: none;
      max-height: 0;
    }
    .readMore {
      display: none;
      color: map.get($theme-colors, "gold");
      @include unna-italic;
      margin-top: 1rem;
      margin-bottom: 1.3rem;
      justify-content: flex-end;
      @include breakpoint-under("md") {
        display: flex;
        gap:.5rem;
      }
    }
    .accordion {
      font-size: 2rem;
      font-style: normal;
    }
  }

  .aboutVideo {
    position: relative;
    left: -50px;
    max-width: 678px;
    height: 448px;
    width: 100%;

    @include breakpoint-under("xl") {
      max-width: 100%;
      height: 100%;
      left: 0;
      padding: 0 1rem;
      padding-bottom: 5.3125rem;
    }

    @include breakpoint-under("md"){
      max-width: calc(100% - 38px);
    }

    .player {
      height: 100% !important;
      @include breakpoint-under("xl") {
        width: 100% !important;
        height: 338px !important;
      }
    }
  }
}
