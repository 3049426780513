@use "sass:map";
@import "../../styles/media_queries";
@import "../../styles/variables";

.ScrollDown {
  position: absolute;
  left: 0;
  right: 0;
  bottom: calc(100% - 600px);
  margin: auto;
  background: transparent;
  width: 38px;
  height: 64px;
  border: 1px solid map.get($theme-colors, "gold-regular");
  border-radius: 100px;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
  cursor: pointer;
  animation: "scroll-arrow-down" 1s 3;
  -webkit-animation: "scroll-arrow-down" 1s 3;

  .arrowDown {
    position: relative;
    top: -10px;
    max-width: 10px;
    transition: color 1s ease 0.125s;

    path {
      transition-duration: 0.4s;
      transition-timing-function: ease-in-out;
      animation: "scroll-arrow-down" 1s 3;
      stroke: map.get($theme-colors, "light");
    }
  }

  &:hover {
    padding-top: 50px;
    .arrowDown {
      path {
        stroke: map.get($theme-colors, "extra-dark");
      }
      color: map.get($theme-colors, "extra-dark");
    }
  }
}

// .arrowUp{
//     transform: scaleY(-1);
// }

.ScrollUp {
  position: initial;
  margin: auto;
  width: 38px;
  height: 64px;
  border: 1px solid map.get($theme-colors, "gold-regular");
  border-radius: 100px;
  cursor: pointer;
  background-color: transparent;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
  animation: "scroll-arrow-down" 1s 3;
  -webkit-animation: "scroll-arrow-down" 1s 3;

  .arrowUp {
    transform: scaleY(-1);
    position: relative;
    top: 12px;
    max-width: 10px;
    transition: color 1s ease 0.125s;

    path {
      transition-duration: 0.4s;
      transition-timing-function: ease-in-out;
      animation: "scroll-arrow-down" 1s 3;
    }
  }

  &:hover {
    padding-bottom: 50px;
  }
}
