@use "sass:map";
@import "../../styles/variables";
@import "../../styles/media_queries";

.outletCard {
  @include mulish-light;
  display: flex;
  flex-direction: column;
  max-width: 450px;
  /*max-height: 730px;*/

  @include breakpoint-under("md") {
    max-width: 100%;
    width: 100%;
    max-height: 100%;
  }

  a {
    transition: all 0.5s ease;
    background: linear-gradient(
        to left,
        transparent 50%,
        map.get($theme-colors, "gold") 50%
      )
      right;
    background-size: 200%;
    background-color: transparent;

    &:hover {
      background-color: map.get($theme-colors, "gold");
      background-position: left;
      color: map.get($theme-colors, "extra-dark") !important;
    }
  }

  span {
    color: map.get($theme-colors, "medium-gold");
  }

  .cardDesc {
    padding: 2rem 2rem 1rem 2rem;
    border-bottom: 1px solid map.get($theme-colors, "gold");

    @include breakpoint-under("md") {
      padding: 0;
    }

    .cardDescImgContainer {
      width: 100%;

      img {
        width: 100%;
      }
    }

    .cardContent {
      @include breakpoint-under("md") {
        padding: 0 2rem 1rem 2rem;
      }

      .cardDescInfo {
        margin: 1rem 0 1rem 0;

        .cardDescInfoLocation {
          font-size: 2.125rem;
          margin: 0;
          line-height: 100%;
          margin: 0 0 1rem 0;
        }

        .cardDescInfoAddress {
          font-size: 1rem;
        }
      }

      .cardDescHours {
        margin: 0 0 1rem 0;
        p {
          margin-top: .6rem;
          margin-bottom: 0;
          font-size: 1.75rem;
          line-height: 100%;
        }
      }

      .cardDescClosingDays {
        p {
          margin: 0;
        }
      }
    }
  }

  .cardButtonLink {
    cursor: pointer;
    font-size: 1.25rem;
    color: #000;
    align-self: center;
    border-top: 1px solid map.get($theme-colors, "gold");
    width: 100%;
    display: none;

    @include breakpoint-under("md") {
      display: flex;
      box-sizing: border-box;
      justify-content: center;
      padding-top: 2.5rem;
      padding-bottom: 3.125rem;
    }
  }

  &.light {
    border: 1px solid map.get($theme-colors, "gold");
    color: map.get($theme-colors, "extra-dark");
    background-color: transparent;

    @include breakpoint-under("md") {
      border: none;
    }

    .cardDesc {
      border-bottom: 1px solid map.get($theme-colors, "gold");
    }

    .cardRefs {
      div {
        &:first-child {
          border-right: 1px solid map.get($theme-colors, "gold");
        }
      }

      @include breakpoint-under("md") {
        border-bottom: 1px solid map.get($theme-colors, "gold");
      }

      font-size: 1rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 65px;

      a {
        padding: 1.5rem;
        flex-basis: 100%;
        text-align: center;
        text-decoration: underline;
        color: map.get($theme-colors, "medium-gold");
        cursor: pointer;
      }
    }

    .cardMap {
      border-bottom: 1px solid map.get($theme-colors, "gold");
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      padding: 1.5rem 2rem;
      text-align: center;
      font-size: 1rem;
      color: map.get($theme-colors, "medium-gold");
      cursor: pointer;
    }
  }
}
