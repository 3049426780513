
@use "sass:map";
@import "_breakpoints";


@mixin breakpoint-over($break) {
	$this_val: map.get($breakpoints, $break);

	@if $this_val {
		@media screen and ( min-width: $this_val ) {
			@content;
		}
	}
}

@mixin breakpoint-under($break) {
	$this_val: map.get($breakpoints, $break);

	@if $this_val {
		@media screen and ( max-width: $this_val ) {
			@content;
		}
	}
}