@import '../../styles/variables';
@import '../../styles/media_queries';

.mailOrderSection{
    @include unna-italic;
    margin: auto;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 2.2rem;
    text-align: center;

    @include breakpoint-under("lg") {
        max-width: 500px;
    }

    @include breakpoint-under("md") {
        font-size: 1.125rem;
        line-height: 1.2;
        max-width: 300px;
    }
    .button{
        margin-top: 2rem;
        width: 210px;
    }
}