@use "sass:map";
@import "../../styles/variables";
@import "../../styles/media_queries";

.BrandCarousel {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: .625rem;
    @include mulish-regular;
    color: map.get($theme-colors, "gray");

    .title {
        font-size: 1.75rem;
        @include unna-italic;
        text-align: center;
        color: map.get($theme-colors, "extra-dark");
        margin-bottom: 2.5rem;
    }

    .subtitle {
        color: map.get($theme-colors, "extra-dark");
        font-size: 1.125rem;
        @include mulish-regular;
        padding: 1.81rem 1.44rem 0 1.44rem;
        box-sizing: border-box;
        text-align: center;
        border-top: .3px solid map.get($theme-colors, "gold");
        width: 995px;
        margin: 0 auto 3.31rem auto;

        @include breakpoint-under("lg") {
            width: calc(100% - 3.125rem);
        }
    }

    .simpleIntroText {
        @include breakpoint-under("md") {
            align-self: center;
        }
    }

    .cardIntroText {
        align-self: center;
        color: map.get($theme-colors, "medium-gold");
        margin: 2% 0 3rem 0;
        text-transform: uppercase;
    }

    .simpleBrandCarousel {
        padding: 2rem 0;
        border-bottom: .3px solid rgba($color: map.get($theme-colors, "gray"), $alpha: .3);
        border-top: .3px solid rgba($color: map.get($theme-colors, "gray"), $alpha: .3);
    }

    .allBrandLink {
        @include unna-italic;
        color: map.get($theme-colors, "gold");
        font-size: 1.25rem;
        align-self: center;
        margin: 1rem 0 0 0;
        cursor: pointer;

        .arrowLink {
            margin: 0 0 0 .5rem;
            font-size: .75rem;
        }
    }
}