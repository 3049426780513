@use "sass:map";
@import "../../styles/variables";

.SimpleLink {
    cursor: pointer;
    color: map.get($theme-colors, "medium-gold");
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    @include unna-italic;

    .linkContent {
       display: flex;
       flex-wrap: nowrap;
       align-items: center;
       gap: .5rem;
    }
}
