@use "sass:map";
@import "../../styles/variables";
@import "../../styles/media_queries";

.CarouselCard {
    box-sizing: border-box;
    width: 304px;
    height: 450px;
    border: 1px solid rgba($color: map.get($theme-colors, "gold"), $alpha: 0.5);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: map.get($theme-colors, "extra-dark");
    @include unna-italic;
    cursor: pointer !important;

    @include breakpoint-under("md") {
        width: 159px;
        height: 235px;
    }

    .cardImg {
        box-sizing: border-box;
        height: 302px;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #000;
        background-size: 100% 100%;
        transition: all 0.75s ease;
        padding: 1.5rem 0;

        @include breakpoint-under("md") {
            height: 159px;
        }

        .cardImgLink {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .cardImgContent {
            opacity: 0;
            transition: all 0.5s ease;

            img {
                object-fit: cover;
                height: 100px;
                width: 150px;
            }
        }

        .description {
            font-style: normal;
            font-size: 1rem;
            line-height: 1.5;
            max-width: 187px;
            margin: auto;
            margin-top: 2rem;
            color: map.get($theme-colors, "light");
            @include mulish-light;
        }

        .cardImgDiscover {
            font-size: 1.25rem;
            color: map.get($theme-colors, "gold");
            margin: 2rem 0 0.5rem 0;
        }
    }

    .cardTitle {
        margin-top: 2.5rem;
        font-size: 1.375rem;
        text-align: center;
        height: 36.8px;
        display: flex;
        align-items: center;
        text-transform: uppercase;

        @include breakpoint-under("md") {
            margin-top: 0.65rem;
            font-size: 1rem;
        }

        .intro {
            font-size: 0.875rem;
            font-style: normal;
            text-transform: uppercase;
            @include mulish-regular;

            @include breakpoint-under("md") {
                font-size: 0.625rem;
            }
        }
    }

    .cardMobileArrow {
        display: none;
    }

    @include breakpoint-under("md") {
        height: 250px;
        padding: 1rem 1rem .5rem 1rem;
        font-size: 1.125rem;

        .cardMobileArrow {
            color: map.get($theme-colors, "gold");
            align-self: flex-end;
            display: block;
            height: 25px;
            display: flex;
            align-items: center;
        }
    }

    @include breakpoint-over("md") {
        &:hover {
            color: map.get($theme-colors, "medium-gold");
            cursor: default;

            .cardImgContent {
                opacity: 1;
            }

            .cardImgContent:last-of-type {
                cursor: pointer;
            }
        }

    }
}


.smallerCarouselCard {
    padding: 1.625rem 1.81rem 2.06rem;
    width: 271.17px;
    height: 419.24px;
    gap: 0;

    @include breakpoint-under("md") {
        padding-bottom: 0;
    }
}