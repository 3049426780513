@use "sass:map";
@import "../../styles/variables";

.Map {
    padding: 20px 0;
}
.title {
    text-align: center;
    padding-top: 4.19rem;
    padding-bottom: 2.81rem;
    color: map.get($theme-colors, "gold");
    @include mulish-regular;
    text-transform: uppercase;
}